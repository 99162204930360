body::-webkit-scrollbar {
  width: 16px !important;     /* Tamaño del scroll en vertical */
  height: 16px !important;   /* Tamaño del scroll en horizontal */
}

body::-webkit-scrollbar-thumb {
  background: var(--ion-color-primary) !important;
  border-radius: 4px !important;
}  

/* Cambiamos el fondo y agregamos una sombra cuando esté en hover */
body::-webkit-scrollbar-thumb:hover {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2) !important;
}

/* Ponemos un color de fondo y redondeamos las esquinas del track */
ion-list::-webkit-scrollbar-track {
  background-color: white !important;
  border-radius: 4px;
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-bottom: 8px;
}

/* //quitar */
ion-menu.md ion-list {
  padding: 20px 0; 
}

ion-header.md.header-menu-container {
  background-color: var(--ion-header-menu-background);
  padding-left: 8px;
  padding-right: 8px;
  position: sticky;
  top: 0;
}

ion-item.md.header-menu {
  padding: 20px 0;
  --background: var(--ion-header-menu-background);
}

ion-item.md.header-menu .header-menu-link:hover {
  cursor: pointer;
  text-decoration: underline
}

ion-menu.md ion-note {
  color: var(--ion-header-menu-color);
  display: inline-block;
  font-size: 16px;
}

ion-menu.md ion-list.inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list.inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}


ion-item.selected {
  --color: var(--ion-color-primary);
} 

ion-list.md.inbox-list {
  padding-top: 0;
}

ion-list.md div.header-menu-list {
  padding-left: .5rem;
  padding-right: .5rem;
}

ion-content.md.inner-scroll {
  padding-top: 0;
}

body.menu-small .split-pane-visible > .split-pane-side {
  min-width: 65px; /* Default: 270px */
  max-width: 65px; /* Same as when menu opens in mobile mode */
}

.toggle-menu {
  width: 40px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  font-size: 19px;
  padding-left: .5rem;
}

.menu-footer-item {
  padding: .5rem;
  box-sizing: border-box;
}