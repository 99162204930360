ion-card.md.register-card {
  background-color: var(--ion-card-background);
  border-radius: 28px;
  z-index: var(--z-fixed);
}

ion-card.md .register-card-title {
  font-size: 16px;
  font-weight: 600;
}

ion-card.md .recover-password {
  text-align: left;
}

ion-list.md.register-list {
  background-color: var(--ion-card-background);
}