.container{
  /* background: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container .card-container {
  height: 250px;
  width: 400px;
}

.container .card-container .front{
  height: 100%;
  width: 100%;
  background: linear-gradient(45deg, rgb(43, 122, 226), rgb(37, 94, 121));
  border-radius: 5px;
  backface-visibility: hidden;
  box-shadow: 0 15px 25px rgba(0,0,0,.2);
  padding: 20px;
  transform: perspective(1000px) rotateY(0deg);
  transition: transform .4s ease-out;
}

.container .card-container .front .image{
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding-top: 10px;
}

.container .card-container .front .image img{
  height: 50px;
}

.container .card-container .front .card-number-box {
  padding: 30px 0;
  font-size: 22px;
  color:#fff;
}

.container .card-container .front .flexbox{
  display: flex;
}

.container .card-container .card-holder-name{
  font-weight: bold;
}

.container .card-container .front .flexbox .box:nth-child(1){
  margin-right: auto;
}

