.container-line-separtor { 
  width: 100%; 
  height: 1px;
  background-color: var(--ion-line-separator-color);
  margin-top: .9rem;
  margin-bottom: .9rem;
}

.container-line-separtor.no-space-top { 
  margin-top: 0;
}

.container-line-separtor.no-space-bottom { 
  margin-bottom: 0;
}