:root {
  --height-profile: 180px;
  --background-icon: rgb(56, 56, 56);
  --color-icon: white
}

.profile-avatar  {
  width: 100%;
  height: var(--height-profile);
  position: relative;
  z-index: var(--z-fixed);
  overflow: hidden;
}

.fondo_profile {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-back);
}

.fondo_profile-img {
  width: 100%;
  height: var(--height-profile);
  object-fit: cover;
}

ion-img.md .avatar {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.profile-avatar-camera {
  position: absolute;
  top: -15px;
  left: 60px;
  width: 50px;
  height: 50px;
  background: var(--background-icon);
  padding: .5rem;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 10px 0px 14px rgba(0, 0, 0, 0.25)
}

.profile-avatar-camera ion-icon {
  color: var(--color-icon);
}

