ion-card.md.login-card {
  background-color: var(--ion-card-background);
  border-radius: 28px;
  z-index: var(--z-fixed);
}

ion-card.md .login-card-title {
  font-size: 16px;
  font-weight: 600;
}

ion-card.md .recover-password {
  text-align: left;
}

ion-list.md.login-list {
  background-color: var(--ion-card-background);
}

ion-list.md ion-item.login-list-item, .login-list-item {
  background-color: var(--ion-item-background);
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.login-grid-input.md, .ios {
  --ion-grid-padding: 0;
}

.login-grid-input.md ion-col.md, .ios {
  --ion-grid-column-padding: 0;
}

.login-list-item.finger {
  padding: .5rem;
  display: grid;
  place-items: center;
  height: 59px;
  cursor: pointer;
}

.ios .login-list-item.finger {
  height: 70px; 
}