ion-content.md.wrapper {
  z-index: var(--z-fixed);
}

.wrapper-content {
  width: 100%;
  background: linear-gradient(180deg, var(--ion-color-primary-fondo) 0%, var(--ion-color-primary-fondo) 100%);
  position: relative;
  z-index: var(--z-fixed);
  overflow: hidden;
}

/* Lines */
.fondo_bottom {
  content: '';
  width: 100%;
  height: 35vh;
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: var(--z-back);
}

/* Box white */
.fondo_top {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-back);
}

.fondo_top img {
  width: 100%;
}

ion-text.md .wrapper-title {
  font-size: 46px;
  font-weight: 600;
}

ion-text.md .wrapper-subtitle {
  font-size: 18px;
}