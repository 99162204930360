.title-card-dashboard {
  font-size: 20px;
}

.card-dashboard--title {
  position: absolute;
  bottom: 30px;
  color: white;
  left: 20px;
  font-size: 18px;
}

.card-dashboard--subtitle {
  position: absolute;
  color: white;
  bottom: 5px;
  left: 20px;
  opacity: .8;
}

.card-dashboard--image {
  /* width: 100%;
  height: 100%;
  display: block; */
  object-fit: cover;
}

.card-dashboard--icon {
  position: absolute;
  color: white;
  top: 20px;
  left: 20px;
  font-size: 18px;
}

.grid-dashboard-services {
  width: 100%;
  /* background-color: red; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.grid-dashboard-services--item {
  min-height: 100px;
  background-color: #C4C4C4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
}

.grid-dashboard-services--item.in-cart img {
  opacity: .6;
  cursor: none;
}

.dashboard-item-icon--delete {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1000;
}
.dashboard-item-icon--cart {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1000;
}

.dashboard-item-icon--plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grid-dashboard-services--item.plus{
  cursor: pointer;
}