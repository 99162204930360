.input-item {
  background-color: var(--ion-item-background);
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.input-item.no-space {
  margin-bottom: 0;
}

.input-item.error {
  border-color: #eb445a;
}